.mm-contact-page [class="form-label"], mm-contact-page h1 {
    color: rgb(210, 255, 7);
    font-size: 25px;
    font-weight: bold;
}

.mm-contact-page h1{
    text-align: center;
    font-size: 30px;
    margin-bottom: 20px;
}


.mm-contact-page [class="form-control"] {
    background-color:transparent;
    color: azure;

}