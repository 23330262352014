footer, footer a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.778);
}

/* Loader */
.loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 150px;
    padding-bottom: 150px;
    width: 80px;
    height: 80px;
    padding-left: 0;
}

.loader:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fa2335;
    border-color: #fa5523 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* nav Banner */
.mm-banner{
    max-height: 138px;
}