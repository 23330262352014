.mm-center {
    position: absolute;
    color: rgb(255, 255, 255);
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.mm-center h2{
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    font-weight: bolder;
    text-shadow: 2px 2px rgb(255, 0, 0);
}
.welcome-message {
    background-image: url("/public/pictures/menu-item-02.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


@media(max-width: 768px) {
    .welcome-message {
        height: 400px;
    }
}

@media(max-width: 576px) {
    .welcome-message {
        height: 300px;
    }

}


.red {
    background-color: rgb(255, 0, 0);
}

.red:hover {
    transform: scale(1.11);
}