/* Ratings */
  
.rating-outer {
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
    color: #fdcc0d;
  }
  
  .rating-outer::before {
    content: '\f006 \f006 \f006 \f006 \f006';
  }
  
  .rating-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
  }
  
  .rating-inner::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    color: #f8ce0b;
    color: #f8ce0b;
  }
  